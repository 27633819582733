import React from "react";

// Customizable Area Start
import { styled } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// Customizable Area End

import TopNavController, {
  Props,
} from "./TopNavController";

export default class TopNav extends TopNavController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <Container>
        <SearchContainer>
          <SearchInput placeholder="Search" />
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
        </SearchContainer>
  
        <UserProfile>
          <NotificationsIconWrapper>
            <NotificationsNoneIcon style={{ color: "#1A4A42", height: "24px", width: "24px" }} />
          </NotificationsIconWrapper>
          <UserImage src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg" alt="User" />
          <UserDetails>
            <UserName>John Doe</UserName>
            <UserEmail>johndoe@email.com</UserEmail>
          </UserDetails>
        </UserProfile>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Container = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    fontFamily: "Raleway",
    "@media (max-width: 600px)": {
      flexDirection: "column", 
      gap: "8px",
      alignItems:"flex-start",
  }
  });
  
  const SearchContainer = styled("div")({
    position: "relative",
    width: "70%",
    maxWidth: "600px",
    "@media (max-width: 600px)": {
      width:"100%"
  }
  });
  
  const SearchInput = styled("input")({
    width: "100%",
    padding: "10px 10px 10px 40px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    fontSize: "16px",
    outline: "none",
    '&::placeholder': {
      color: "#1A4A42",
      opacity: 1,
      fontWeight:"700"
    },
  });
  
  const SearchIconWrapper = styled("div")({
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#1A4A42",
  });
  
  const NotificationsIconWrapper = styled("div")({
    width: "40px",
    height: "40px",
    borderRadius: "12px",
    backgroundColor: "#1A4A424D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  });
  
  const UserProfile = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginLeft:"10px"
  });
  
  const UserImage = styled("img")({
    width: "40px",
    height: "40px",
    borderRadius: "12px",
    objectFit: "cover",
  });
  
  const UserDetails = styled("div")({
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    lineHeight: "1.2",
    gap: "5px"
  });
  
  const UserName = styled("span")({
    fontWeight: "bold",
    fontFamily: "Raleway"
  });
  
  const UserEmail = styled("span")({
    color: "#555",
    fontFamily: "Raleway"
  });
const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};
// Customizable Area End
