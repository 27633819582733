import React from "react";
// Customizable Area Start
import { styled } from "@mui/styles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import TopNav from "../../navigationmenu/src/TopNav.web";

// Customizable Area End

import LandinPageController, {
    Props,
} from "./LandingPageController";

export default class LandingPage extends LandinPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const arr = new Array(20).fill(0)
        return (
            // Customizable Area Start
            <MainContainer>
                <TopNav navigation={this.props.navigation} id="Landing Page"/>
                <Divider />
                <EventContainer>
                    <EventButton>Events</EventButton>
                    <EventButton>Triggers</EventButton>
                </EventContainer>
                <CardContainer>
                    <Card>
                        <div>
                            <CardHeader>Permissions</CardHeader>
                            <CardBody>
                                Permissions created on Total Life CRM
                            </CardBody>
                        </div>
                        <CardFooter>
                            <p style={{ fontFamily: "Outfit", fontWeight: "700",position:'relative',top:"20" }}><span style={{ fontSize: "46px", color: "#E46760", fontWeight: "500", marginRight: "2px" }}>18</span> Permissions</p>
                        </CardFooter>
                    </Card>
                    <Card onClick={this.handleCreate} data-test-id="pipeline-card">
                        <div>
                            <CardHeader>Pipelines</CardHeader>
                            <CardBody>
                                Pipelines created on Total Life CRM
                            </CardBody>
                        </div>
                        <CardFooter>
                            <p style={{ fontFamily: "Outfit", fontWeight: "700",position:'relative',top:"20" }}><span style={{ fontSize: "46px", color: "#E46760", fontWeight: "500", marginRight: "2px" }}>14</span> Pipelines</p>
                        </CardFooter>
                    </Card>

                    <Card>
                        <div>
                            <CardHeader>Add New Pipeline</CardHeader>
                            <CardBody>
                                Create New pipeline for Admins.
                            </CardBody>
                        </div>
                        <CardFooter>
                            <CreateButton onClick={this.handleCreate}>Create</CreateButton>
                        </CardFooter>

                    </Card>
                </CardContainer>
                <div style={{ display: "flex",flexWrap:"wrap", gap: "20px", boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.06)", padding: "20px 0" }}>
                    <SectionContainer>
                        <SectionHeader>
                            <p style={{ fontWeight: "700", fontSize: "18px" }}>Tasks List</p>
                            <StyledButton>View All</StyledButton>
                        </SectionHeader>
                        <ListHeader>
                            <p style={{ fontWeight: "700", fontSize: "14px" }}>Name</p>
                            <p style={{ fontWeight: "700", fontSize: "14px" }}>Actions</p>
                        </ListHeader>
                        {arr.map((_, index) => (
                            <ListItem key={index}>
                                <p style={{ fontWeight: "700", fontSize: "14px" }}>Task {index + 1}</p>
                                <IconActions>
                                    <EditIcon style={iconStyle} />
                                    <DeleteOutlineIcon style={deleteIconStyle} />
                                </IconActions>
                            </ListItem>
                        ))}
                    </SectionContainer>

                    <SectionContainer>
                        <SectionHeader>
                            <p style={{ fontWeight: "700", fontSize: "18px" }}>Pipelines</p>
                            <StyledButton>View All</StyledButton>
                        </SectionHeader>
                        <ListHeader>
                            <p style={{ fontWeight: "700", fontSize: "14px" }}>Name</p>
                            <p style={{ fontWeight: "700", fontSize: "14px" }}>Actions</p>
                        </ListHeader>
                        {arr.map((_, index) => (
                            <ListItem key={index}>
                                <p style={{ fontWeight: "700", fontSize: "14px" }}>Pipeline {index + 1}</p>
                                <IconActions>
                                    <EditIcon style={iconStyle} />
                                    <DeleteOutlineIcon style={deleteIconStyle} />
                                </IconActions>
                            </ListItem>
                        ))}
                    </SectionContainer>
                </div>
            </MainContainer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer = styled("div")({
    padding: "20px",
    justifyContent: "center",
    boxShadow: "rgba(99, 99, 99, 0.6) 0px 2px 8px 0px",
    borderRadius: "12px",
    backgroundColor: "#fff",
    fontFamily:"Raleway",
    maxWidth:"100%",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
        padding: "10px", 
    },
    overflowX: "hidden",

    "@media (max-width: 400px)": {
        padding: "4px",
    }
});

const EventContainer = styled("div")({
    display: "flex", 
    justifyContent: "flex-end",
    flexWrap:"wrap", 
    gap: "5px", 
    marginBottom: "10px", 
})
const EventButton = styled("button")({
    backgroundColor: "#1A4A42",
    color: "#fff",
    padding: "8px 16px",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    cursor: "pointer",
    width: "auto",
    fontWeight: "700",
    height: "100%",
});
const CardContainer = styled("div")({
    display: "flex",
    justifyContent:"space-between",
    flexWrap: "wrap",
    width: "100%",
    gap:"20px",
    "@media (max-width: 400px)": {
        flexDirection: "column", 
        gap: "8px",
    }
});

const Card = styled("div")({
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "rgba(99, 99, 99, 0.5) 0px 2px 6px 0px",
    padding: "10px",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth:"100%"
});

const CardFooter = styled("div")({
    fontSize: "18px",
    fontWeight: "700",
    marginTop: "4px",
    position: "relative"
});

const CardHeader = styled("div")({
    margin: "5px 0",
    fontSize: "16px",
    fontWeight: "700",
});

const CardBody = styled("div")({
    fontSize: "14px",
});

const SectionContainer = styled("div")({
    flex: 1,
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "rgba(99, 99, 99, 0.6) 0px 2px 8px 0px",

})

const SectionHeader = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
});

const CreateButton = styled("button")({
    backgroundColor: "#E46760",
    color: "#fff",
    padding: "10px 16px",
    border: "none",
    borderRadius: "8px",
    fontSize: "14px",
    cursor: "pointer",
    height: "100%",
    fontWeight: "700",
});

const StyledButton = styled("button")({
    backgroundColor: "#1A4A424D",
    color: "#1A4A42",
    padding: "8px 16px",
    border: "none",
    borderRadius: "8px",
    fontSize: "14px",
    cursor: "pointer",
    width: "auto",
    height: "100%",
    fontFamily: "700"
});

const ListHeader = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px",
    backgroundColor: "#1A4A421A",
});

const ListItem = styled("div")({
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    padding: "0 20px",
});

const IconActions = styled("div")({
    display: "flex",
    gap: "6px",
});

const iconStyle = {
    backgroundColor: "#1A4A424D",
    color: "#064E3B",
    padding: "3px",
    borderRadius: "6px",
};

const deleteIconStyle = {
    backgroundColor: "#EF444433",
    color: "#DC2626",
    padding: "3px",
    borderRadius: "6px",
};

const Divider = styled("hr")({
    width: "100%",
    margin: "15px 0",
    backgroundColor: "#1A4A4233",
});

// Customizable Area End
