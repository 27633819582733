import React from "react";

// Customizable Area Start
import HomeIcon from '@mui/icons-material/Home';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PermissionsIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { styled } from "@mui/styles";
import { logoImage } from "./assets";

interface NavItemProps {
  isActive?: boolean;
}
// Customizable Area End

import SideNavController, {
  Props,
} from "./SideNavController";

export default class SideNav extends SideNavController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const sideData = [
        {
          id: 1,
          path: "/",
          text: "Dashboard",
          icon: <HomeIcon />
        },
        {
          id: 2,
          path: "/Cfpipelines1",
          text: "Pipelines",
          icon: <BarChartOutlinedIcon />
        },
        {
          id: 3,
          path: "/permissions",
          text: "Permissions",
          icon: <PermissionsIcon />
        },
      ];
      const settingsData = [
        {
          path: "/settings",
          text: "Settings",
          icon: <SettingsIcon />
        },
        {
          path: "/logout",
          text: "LogOut",
          icon: <ExitToAppIcon />
        },
      ];

    return (
        <Container>
        <div style={{width:"80%"}}>
          <Logo onClick={() => this.navigate("/")} data-test-id="logobutton">
           <LogoImage src={logoImage.default} alt="logo"/>
          </Logo>
          <Divider />
          {sideData.map((item) => (
            <NavItem
              key={item.id}
              onClick={() => this.navigate(item.path)}
              isActive={this.isActive(item.path)}
              data-test-id="navigation-item"
            >
              <NavIcon isActive={this.isActive(item.path)}>
                {item.icon}
              </NavIcon>
              <NavText isActive={this.isActive(item.path)}>{item.text}</NavText>
            </NavItem>
          ))}
        </div>
        <div style={{width:"80%"}}>
          <Divider />
          {settingsData.map((item) => (
            <SettingsContainer
              key={item.path}
              onClick={() => this.navigate(item.path)}
              isActive={this.isActive(item.path)}
              data-test-id="settings-icon"
            >
              <NavIcon isActive={this.isActive(item.path)}>
                {item.icon}
              </NavIcon>
              <NavText isActive={this.isActive(item.path)}>{item.text}</NavText>
            </SettingsContainer>
          ))}
        </div>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Container = styled("div")({
    paddingTop: "20px",
    display: "flex",
    fontFamily:"Raleway",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor:"#1A4A42E5",
    height: "100%",
    boxSizing:"border-box",
    width:"100%",
    borderRadius:"12px",
    boxShadow: "rgba(99, 99, 99, 0.6) 0px 2px 8px 0px",
    "@media (max-width: 640px)": {
      width: "60px", 
    }
  });
  
  const Logo = styled("div")({
    cursor: "pointer",
    backgroundColor:"rgba(26, 74, 66, 0.9)",
    padding:"10px",
    borderRadius:"12px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  });
  
  const LogoImage = styled("img")({
    "@media (max-width: 480px)" :{
      width:"50px"
    },
    "@media (min-width: 481px) and (max-width: 767px)" : {
      width:"70px"
  
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      width:"100px"
    },
    "@media (min-width: 1025px) and (max-width: 1280px)": {
      width:"120px"
    },
    "@media (min-width: 1281px) and (max-width: 1440px)": {
      width:"160px"
    },
    "@media (min-width: 1441px)": {
      width:"300px",
  
    }
  })
  
  const Divider = styled("hr")({
    width: "100%",
    margin: "10px 0",
    backgroundColor: "#E46760",
    color:"#E46760",
    boxSizing: "border-box"
  });
  
  const NavItem = styled("div")(({ isActive }:NavItemProps) => ({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    marginBottom: "20px",
    color: isActive ? "#E46760" : "#FFFFFF",
    fontWeight: isActive ? "700" : "500",
    "@media (max-width: 480px)" :{
      justifyContent:"center"
    },
    "@media (min-width: 481px) and (max-width: 767px)" : {
      fontSize:"10px"
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      fontSize:"12px"
    },
    "@media (min-width: 1025px) and (max-width: 1280px)": {
      fontSize:"18px"
    },
    "@media (min-width: 1281px) and (max-width: 1440px)": {
  fontSize:"20px"
  
    },
    "@media (min-width: 1441px)": {
  fontSize:"35px"
    }
  }));
  
  const NavIcon = styled("div")(({ isActive }:NavItemProps) => ({
    color: isActive ? "#E46760" : "#FFFFFF",
    height:"100%"
  }));
  
  const NavText = styled("p")(({ isActive }:NavItemProps) => ({
    margin: 0,
    color: isActive ? "#E46760" : "#FFFFFF",
    fontWeight: isActive ? "700" : "500",
    "@media (max-width: 600px)": {
      display: "none",
    }
  }));
  
  const SettingsContainer = styled("div")(({ isActive }: NavItemProps) => ({
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    marginBottom: "10px",
    width: "100%",
    color: isActive ? "#E46760" : "#FFFFFF",
    fontWeight: isActive ? "700" : "500",
    "@media (max-width: 600px)": {
      justifyContent: "center",
    }
  }));
// Customizable Area End
